import { ActionContext, EditeurState, initialFillColor } from "./typing";
import * as Shapes from "@/assets/Shape";
import { ShapeHistoryItem } from "./historyTyping";

export const editeur = {
  namespaced: true,
  state: new EditeurState(),
  actions: {
    clear({ commit }: ActionContext) {
      commit("clearHistory");
      commit("clearCanvas");
    },
    stopDrawing({ commit }: ActionContext) {
      commit("clearHistory");
      commit("setDrawing", false);
      commit("addShape");
      commit("initializeShape");
    },
    setShapeType({ commit }: ActionContext, type: Shapes.shapeTypes) {
      commit("setShapeType", type);
      commit("initializeShape");
    },
    undo({ commit, state }: ActionContext) {
      // updates history
      const currentState = state as EditeurState;
      if (!currentState.isDrawing) {
        commit("undo");
      } else {
        // stops current drawing
        commit("setDrawing", false);
        commit("initializeShape");
      }
    },
    redo({ commit }: ActionContext) {
      commit("redo");
    }
  },
  mutations: {
    new(state: EditeurState) {
      // réinitialiser le state au chargement de la page
      Object.assign(state, new EditeurState());
    },

    clearCanvas(state: EditeurState) {
      state.shapes = [];

      // finds previous clear if exists
      let i = state.history.length - 1;
      while (i >= 0) {
        const historyItem = state.history[i];
        if (historyItem.type === "clear") break;
        i--;
      }
      // updates history
      state.history.push({ type: "clear", value: i });
      state.historyIndex++;
    },
    initializeShape(state: EditeurState) {
      state.currentShape = new Shapes[state.shapeType](
        state.fillColor.hex8,
        state.strokeColor.hex8,
        parseInt(state.strokeWidth)
      );
    },
    addShape(state: EditeurState) {
      state.shapes.push(state.currentShape);
      // history update
      state.history.push({
        type: "shape",
        value: state.currentShape
      });
      state.historyIndex++;
    },
    setDrawing(state: EditeurState, val: boolean) {
      state.isDrawing = val;
    },
    // TODO update history (change settings)
    setStrokeColor(state: EditeurState, val: typeof initialFillColor) {
      state.strokeColor = val;
      state.currentShape.strokeColor = val.hex8;
    },
    setFillColor(state: EditeurState, val: typeof initialFillColor) {
      state.fillColor = val;
      state.currentShape.fillColor = val.hex8;
    },
    setStrokeWidth(state: EditeurState, width: string) {
      state.strokeWidth = width;
      state.currentShape.strokeWidth = parseInt(width);
    },
    setShapeType(state: EditeurState, shapeType: Shapes.shapeTypes) {
      state.shapeType = shapeType;
    },
    // -- history --
    clearHistory(state: EditeurState) {
      // clear old leftover history
      if (state.historyIndex < state.history.length - 1) {
        const numberToDelete = state.history.length - state.historyIndex;
        state.history.splice(state.historyIndex + 1, numberToDelete);
      }
    },
    undo(state: EditeurState) {
      const lastHistoryItem = state.history[state.historyIndex];
      state.historyIndex--;

      // rewinds
      switch (lastHistoryItem.type) {
        case "shape":
          state.shapes.pop();
          break;
        case "clear": {
          const previousClear = lastHistoryItem.value as number;
          for (let i = previousClear + 1; i < state.historyIndex + 1; i++) {
            const item = state.history[i];
            if (item.type === "shape")
              state.shapes.push(item.value as Shapes.Shape);
          }
          break;
        }

        default:
          console.warn("NOT IMPLEMENTED YET : UNDO", lastHistoryItem.type);
          break;
      }
    },
    redo(state: EditeurState) {
      const nextHistoryItem = state.history[state.historyIndex + 1];
      state.historyIndex++;

      switch (nextHistoryItem.type) {
        case "shape": {
          const shapeHistoryItem = nextHistoryItem as ShapeHistoryItem;
          // re-applies shape
          state.shapes.push(shapeHistoryItem.value);
          break;
        }
        case "clear":
          state.shapes = [];
          break;

        default:
          console.warn("NOT IMPLEMENTED YET : REDO", nextHistoryItem.type);
          break;
      }
    }
  }
};
