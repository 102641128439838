import dayjs from "dayjs";
import axios from "axios";
import { UserCreate } from "@/assets/User";
import { API_URL } from "./api";

/**
 * Pour s'inscrire et s'authentifier
 */
class AuthService {
  /**
   * Tente d'authentifier un utilisateur et de récupérer son JWT correspondant
   * @param user {User} Utilisateur à authentifier
   */
  async login(user: UserCreate) {
    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("username", user.username);
    formData.append("password", user.password);

    const response = await axios.post(API_URL + "/api/auth/signin", formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" }
    });

    // authentification réussie
    if (response.data.access_token) {
      localStorage.setItem("access_token", response.data.access_token);
      const ttl = response.data.ttl;
      const expireDate = dayjs()
        .add(ttl, "seconds")
        .toISOString();
      localStorage.setItem("expire_date", expireDate);
    } else {
      console.warn("enregistrement de l'utilisateur échoué");
    }
    return response;
  }

  /**
   * Supprime le JWT du localStorage, ce qui empêche d'effectuer des requêtes authentifiées
   * (<=> l'utilisateur est déconnecté)
   */
  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_date");
  }

  /**
   * Tente d'enregistrer un nouvel utilisateur
   * @param user {User}
   */
  register(user: UserCreate) {
    return axios.post(API_URL + "/api/auth/signup", {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
