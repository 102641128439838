import Point from "./Point";
const SQRT2 = Math.sqrt(2);

/** Les différentes types de formes implémentés */
export enum shapeTypes {
  line = "Line",
  path = "Path",
  polygon = "Polygon",
  circle = "Circle",
  ellipse = "Ellipse",
  rectangle = "Rectangle"
}
/** Icônes des boutons de l'éditeur */
export enum shapeIcons {
  line = '<span class="font-bold">/</span>',
  path = '<i class="fas fa-bezier-curve"></i>',
  polygon = '<i class="fas fa-lg fa-draw-polygon"></i>',
  circle = '<i class="far fa-lg fa-circle"></i>',
  ellipse = '<i class="far fa-lg fa-circle transform scale-y-150"></i>',
  rectangle = '<i class="far fa-lg fa-square"></i>'
}

export class Shape {
  type: shapeTypes | null = null;
  fillColor?: string;
  strokeColor?: string;
  strokeWidth?: number;

  constructor(fillColor?: string, strokeColor?: string, strokeWidth?: number) {
    this.fillColor = fillColor;
    this.strokeColor = strokeColor;
    this.strokeWidth = strokeWidth;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  preview(p: Point) {
    console.warn("preview not implemented", this.type);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  draw(p: Point) {
    console.warn("draw not implemented", this.type);
  }
}

export class Polygon extends Shape {
  type = shapeTypes.polygon;
  points: Point[] = [];

  preview(p: Point) {
    if (this.points.length > 1) this.points.pop();
    this.points.push(p);
  }

  draw(p: Point) {
    this.points.push(p);
  }
}

export class Circle extends Shape {
  type = shapeTypes.circle;
  center?: Point;
  radius = 0;

  preview(p: Point) {
    if (this.center) this.radius = this.center.distance(p);
  }
  draw(p: Point) {
    if (!this.center) this.center = p;
    else this.preview(p);
  }
}
export class Ellipse extends Shape {
  type = shapeTypes.ellipse;
  center?: Point;
  radiusX = 0;
  radiusY = 0;

  preview(p: Point) {
    if (this.center) {
      this.radiusX = Math.abs(this.center.x - p.x) * SQRT2;
      this.radiusY = Math.abs(this.center.y - p.y) * SQRT2;
    }
  }
  draw(p: Point) {
    if (!this.center) this.center = p;
    else this.preview(p);
  }
}
export class Rectangle extends Shape {
  type = shapeTypes.rectangle;
  height = 0;
  width = 0;
  first?: Point;
  originX = 0;
  originY = 0;

  preview(p: Point) {
    if (this.first) {
      // case negative values
      if (this.first.x - p.x > 0) {
        this.originX = p.x;
        p.x = this.first.x;
      } else {
        this.originX = this.first.x;
      }
      if (this.first.y - p.y > 0) {
        this.originY = p.y;
        p.y = this.first.y;
      } else {
        this.originY = this.first.y;
      }
      this.width = p.x - this.originX;
      this.height = p.y - this.originY;
    }
  }
  draw(p: Point) {
    if (!this.first) {
      this.first = Point.copy(p);
    } else this.preview(p);
  }
}
export class Line extends Shape {
  type = shapeTypes.line;
  origin?: Point;
  x2 = 0;
  y2 = 0;

  preview(p: Point) {
    this.x2 = p.x;
    this.y2 = p.y;
  }
  draw(p: Point) {
    if (!this.origin) this.origin = p;
    else this.preview(p);
  }
}
export class Path extends Shape {
  type = shapeTypes.path;
  points: Point[] = [];

  preview(p: Point) {
    if (this.points.length > 1) this.points.pop();
    this.points.push(p);
  }

  draw(p: Point) {
    if (this.points.length < 4) {
      this.points.push(p);
    }
  }
}
