




































































import Vue from "vue";

import UserService from "@/services/user.service";
import ImageService from "@/services/image.service";
import { API_URL } from "@/services/api";

import Modal from "@/components/Modal.vue";
import ButtonComponent from "@/components/form/ButtonComponent.vue";

export default Vue.extend({
  name: "ImageComponent",

  components: {
    Modal,
    ButtonComponent
  },

  props: {
    id: { type: Number, default: -1 },
    path: { type: String, default: "" },
    public: Boolean,
    title: { type: String, default: "" },
    // eslint-disable-next-line
    "owner_id": {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      apiUrl: API_URL + "/static/",
      modalOpen: false,
      ownerName: "",
      isLiked: false
    };
  },

  computed: {
    src(): string {
      return this.apiUrl + this.path;
    },
    publicClass(): Record<string, boolean> {
      return {
        border: this.public,
        "ring-2 ring-red-500": !this.public
      };
    },
    liked(): Record<string, boolean> {
      return {
        "text-danger hover:text-danger": this.isLiked,
        "text-light hover:text-light": !this.isLiked
      };
    },
    loggedIn(): boolean {
      return this.$store.state.auth.loggedIn;
    }
  },

  async created() {
    if (this.loggedIn) {
      if (
        !Object.keys(this.$store.state.cache.likes).includes(this.id.toString())
      )
        this.isLiked = await this.$store.dispatch("cache/getHasLiked", this.id);
      else this.isLiked = this.$store.state.cache.likes[this.id];
    }
  },

  methods: {
    async toggleModal() {
      // fetch username for the first time
      if (!this.ownerName) {
        // si la donnée n'est pas stockée en cache, on va la fetch
        if (
          !Object.keys(this.$store.state.cache.usernames).includes(
            this.owner_id.toString()
          )
        )
          this.ownerName = await this.$store.dispatch(
            "cache/getUsername",
            this.owner_id
          );
        // lecture dans le cache
        else this.ownerName = this.$store.state.cache.usernames[this.owner_id];
      }
      this.modalOpen = !this.modalOpen;
    },

    async downloadImage() {
      const response = await ImageService.getImage(this.path);
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.download = this.title + ".svg";
      link.href = url;
      link.click();
      URL.revokeObjectURL(url);

      // gain d'un pix point pour l'auteur si utilisateur != auteur
      if (this.loggedIn && this.$store.state.auth.user.id !== this.owner_id)
        UserService.addPixPoint(this.owner_id);
    },

    toggleLike() {
      this.isLiked = !this.isLiked;
      const payload = {
        imageId: this.id,
        like: this.isLiked,
        ownerId: this.owner_id
      };
      this.$store.dispatch("cache/setLike", payload);
    }
  }
});
