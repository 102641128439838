import dayjs from "dayjs";

/**
 * Vérifie dans le localStorage si un utilisateur a été authentifié.
 * Si oui, renvoie un header d'authentification.
 * Sinon null.
 */
export default function authHeader() {
  const accessToken = localStorage.getItem("access_token");
  const expire = localStorage.getItem("expire_date");
  if (expire && accessToken) {
    const expireDate = dayjs(expire);
    if (expireDate.isAfter(dayjs()) && accessToken)
      return { Authorization: "Bearer " + accessToken };
  }
  // s'il n'y a pas d'authentification
  return null;
}
