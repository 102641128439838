import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "./api";

/**
 * Pour accéder aux données concernant les images
 */
class ImageService {
  getPublicImages() {
    return axios.get(API_URL + "/api/images/public");
  }

  getRecentPublicImages(skip = 0, limit = 6) {
    return axios.get(`${API_URL}/api/images/public/recent/${skip}/${limit}`);
  }

  getPopularPublicImages(skip = 0, limit = 6) {
    return axios.get(`${API_URL}/api/images/public/popular/${skip}/${limit}`);
  }

  getImage(path: string) {
    return axios.get(`${API_URL}/static/${path}`, { responseType: "blob" });
  }

  getHasLiked(imageId: number) {
    return axios.get(`${API_URL}/api/likes/${imageId}`, {
      headers: authHeader()
    });
  }

  addLike(imageId: number) {
    console.log("❤️ " + imageId);
    return axios.post(`${API_URL}/api/likes/${imageId}`, null, {
      headers: authHeader()
    });
  }

  deleteLike(imageId: number) {
    console.log("💔 " + imageId);
    return axios.delete(`${API_URL}/likes/${imageId}`, {
      headers: authHeader()
    });
  }
}

export default new ImageService();
