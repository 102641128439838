import UserService from "@/services/user.service";
import ImageService from "@/services/image.service";
import { ActionContext, CacheState } from "./typing";

export const cache = {
  namespaced: true,
  state: new CacheState(),
  actions: {
    async getUsername({ commit }: ActionContext, userId: number) {
      const response = await UserService.getUserName(userId);
      const username = response.data;
      commit("setUsername", { userId, username });
      return username;
    },
    async getHasLiked({ commit }: ActionContext, imageId: number) {
      const response = await ImageService.getHasLiked(imageId);
      const like = response.data;
      commit("setLike", { imageId, like });
      return like;
    },
    async setLike(
      { commit, rootState }: ActionContext,
      {
        imageId,
        like,
        ownerId
      }: { imageId: number; like: boolean; ownerId: number }
    ) {
      if (like) {
        ImageService.addLike(imageId);
        if (rootState.auth.user.id !== ownerId)
          UserService.addPixPoint(ownerId, 5);
      } else {
        ImageService.deleteLike(imageId);
        if (rootState.auth.user.id !== ownerId)
          UserService.addPixPoint(ownerId, -5);
      }
      commit("setLike", { imageId, like });
      return like;
    },
    clearLikes({ commit }: ActionContext) {
      commit("clearLikes");
    }
  },
  mutations: {
    setUsername(
      state: CacheState,
      { userId, username }: { userId: number; username: string }
    ) {
      state.usernames[userId] = username;
    },
    setLike(
      state: CacheState,
      { imageId, like }: { imageId: number; like: boolean }
    ) {
      state.likes[imageId] = like;
    },
    clearLikes(state: CacheState) {
      state.likes = {};
    }
  }
};
