import UserService from "@/services/user.service";
import { User } from "@/assets/User";
import { ActionContext, ProfilState } from "./typing";

export const profil = {
  namespaced: true,
  state: new ProfilState(),
  actions: {
    peekAtUser({ commit }: ActionContext, username: string) {
      return UserService.getUserByName(username).then(
        response => {
          const user: User = response.data;
          commit("userPeekSuccess", user);
          return Promise.resolve(user);
        },
        error => {
          commit("userPeekFailure", username);
          return Promise.reject(error);
        }
      );
    },
    removeUser({ commit }: ActionContext) {
      commit("userRemove");
    }
  },
  mutations: {
    userPeekSuccess(state: ProfilState, user: User) {
      state.user = user;
    },
    userPeekFailure(state: ProfilState, username: string) {
      state.user = null;
      console.warn(username + " not found");
    },
    userRemove(state: ProfilState) {
      state.user = null;
    }
  }
};
