





























import dayjs from "dayjs";
import Vue from "vue";
import { Route } from "vue-router";

import Navbar from "./components/navbar/Navbar.vue";
import FlashMessage from "@/components/FlashMessage.vue";

const App = Vue.extend({
  components: {
    FlashMessage,
    Navbar
  },

  data() {
    return {
      transitionName: "slideLeft"
    };
  },

  created() {
    const accessToken = localStorage.getItem("access_token");
    const expire = localStorage.getItem("expire_date");
    if (expire && accessToken) {
      const expireDate = dayjs(expire);
      if (expireDate.isAfter(dayjs()))
        // trouvé un token non périmé en localStorage
        this.$store.dispatch("auth/loginFromToken", accessToken);
      else
        console.warn(
          expireDate.toString() +
            " < " +
            dayjs().toString() +
            " >> token périmé"
        );
    }
  },

  watch: {
    $route(to: Route, from: Route) {
      // transitions
      if (to.meta.index > from.meta.index) this.transitionName = "slideLeft";
      else if (to.meta.index < from.meta.index)
        this.transitionName = "slideRight";

      // session périmée ?
      if (to.name !== "Connexion" && this.$store.state.auth.loggedIn) {
        const expire = localStorage.getItem("expire_date");
        if (expire) {
          const expireDate = dayjs(expire);
          // la session a expirée !
          if (expireDate.isBefore(dayjs())) {
            this.$store.commit("auth/loginExpired");
            this.$router.replace({ name: "Connexion" });
          }
        }
      }
    }
  }
});
export default App;
