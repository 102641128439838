














import Vue from "vue";
export default Vue.extend({
  name: "ButtonComponent",
  props: {
    type: {
      type: String,
      default: "button"
    },
    theme: {
      type: String,
      default: "primary"
    },
    rounded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    colorClass(): string {
      let result = " ";
      if (!this.disabled) {
        result += "shadow hover:shadow-inner ";
        switch (this.theme) {
          case "primary":
            result += "bg-dark text-primary hover:text-white hover:bg-blue-500";
            break;
          case "secondary":
            result += "bg-white text-dark hover:bg-blue-200";
            break;
          case "toggle":
            result += "bg-primary hover:bg-blue-200";
            break;
          case "danger":
            result += "bg-red-700 hover:bg-red-500 text-white";
            break;

          default:
            result += "";
        }
      } else result += "bg-gray-300 text-gray-500 shadow-none cursor-default";
      return result;
    },
    roundedClass(): string {
      return this.rounded ? "rounded-full" : "p-2";
    }
  }
});
