import { ActionContext, FlashState } from "./typing";

export const flash = {
  namespaced: true,
  state: new FlashState(),

  actions: {
    flash({ commit }: ActionContext, message: string) {
      // affiche le message
      commit("show", message);
      // cache le message après l'animation
      setTimeout(() => {
        commit("hide");
      }, 6000);
    }
  },

  mutations: {
    show(state: FlashState, message: string) {
      state.message = message;
      state.show = true;
    },
    hide(state: FlashState) {
      state.show = false;
    }
  }
};
