import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import Accueil from "../views/Accueil.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Accueil",
    component: Accueil,
    meta: { index: 0 }
  },
  // {
  //   path: "/boutique",
  //   name: "Boutique",
  //   component: () =>
  //     import(/* webpackChunkName: "boutique" */ "../views/Boutique.vue"),
  //   meta: {index: 1}
  // }
  {
    path: "/editeur",
    name: "Editeur",
    component: () =>
      import(/* webpackChunkName: "editeur" */ "../views/Editeur.vue"),
    meta: { index: 2 }
  },
  {
    path: "/profil/:username",
    name: "Profil d'utilisateur",
    component: () =>
      import(/* webpackChunkName: "profil" */ "../views/Profil.vue"),
    props: true,
    meta: { index: 3 }
  },
  {
    path: "/profil",
    name: "Mon profil",
    component: () =>
      import(/* webpackChunkName: "profil" */ "../views/Profil.vue"),
    meta: { index: 4 }
  },
  {
    path: "/connexion",
    name: "Connexion",
    component: () =>
      import(/* webpackChunkName: "connexion" */ "../views/Connexion.vue"),
    meta: { index: 5 }
  },
  {
    path: "/inscription",
    name: "Inscription",
    component: () =>
      import(/* webpackChunkName: "connexion" */ "../views/Inscription.vue"),
    meta: { index: 7 }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to: Route, from: Route, next: Function) => {
  document.title = "Pixit - " + to.name;
  next();
});

export default router;
