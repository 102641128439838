import { User } from "@/assets/User";
import * as Shapes from "@/assets/Shape";
import { shapeTypes } from "@/assets/Shape";
import { HistoryItem } from "./historyTyping";

export class AuthState {
  loggingIn = false;
  loggedIn!: boolean;
  expired = false;
  user!: User;

  constructor(loggedIn = false, user = new User()) {
    this.loggedIn = loggedIn;
    this.user = user;
  }
}

export class ProfilState {
  user: User | null = null;
}

export class CacheState {
  usernames: Record<number, string> = {};
  likes: Record<number, boolean> = {};
}

/* vue-color picker */
export const initialFillColor = {
  hsl: { h: 0, s: 0, l: 0, a: 1 },
  hex: "#000000",
  hex8: "#000000FF",
  rgba: { r: 0, g: 0, b: 0, a: 1 },
  hsv: { h: 0, s: 0, v: 0, a: 1 },
  oldHue: 0,
  source: "hsva",
  a: 1
};

const initialStrokeColor = {
  hsl: { h: 201.4, s: 1, l: 0.47, a: 1 },
  hex: "#0099EE",
  hex8: "#0099EEFF",
  rgba: { r: 0, g: 153, b: 238, a: 1 },
  hsv: { h: 201.4, s: 1, v: 0.93, a: 1 },
  oldHue: 201.4,
  source: "hex",
  a: 1
};

export class EditeurState {
  isDrawing = false;
  fillColor = initialFillColor;
  strokeColor = initialStrokeColor;
  strokeWidth = "1";
  shapeType = shapeTypes.line;
  shapeTypes = shapeTypes;
  currentShape: Shapes.Shape = new Shapes[this.shapeType]();
  shapes: Shapes.Shape[] = [];
  history: HistoryItem[] = [];
  historyIndex = -1;
}

export class FlashState {
  show = false;
  message = "lorem ipsum dolor";
}

export class State {
  auth = new AuthState();
  profil = new ProfilState();
  cache = new CacheState();
  editeur = new EditeurState();
  flash = new FlashState();
}

export interface ActionContext {
  commit: Function;
  dispatch: Function;
  rootState: State;
  state:
    | State
    | AuthState
    | ProfilState
    | CacheState
    | EditeurState
    | FlashState;
}
