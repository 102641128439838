






















































import Vue from "vue";

import Logo from "@/components/images/Logo.vue";
import NavLink from "./NavLink.vue";

export default Vue.extend({
  name: "Navbar",
  components: {
    Logo,
    NavLink
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
    loginExpired() {
      return this.$store.state.auth.expired;
    }
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("cache/clearLikes");
      this.$router.push("/connexion"); // TODO push("/")
    }
  }
});
