import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "./api";

/**
 * Pour accéder aux données d'un utilisateur selon le niveau de droits
 */
class UserService {
  getCurrentUser() {
    return axios.get(API_URL + "/api/users/me", { headers: authHeader() });
  }

  getUserImages(userId: number) {
    return axios.get(API_URL + "/api/images/owner/" + userId);
  }

  getUserLikes(userId: number) {
    return axios.get(API_URL + "/api/likes/user/" + userId);
  }

  getUserName(userId: number) {
    return axios.get(API_URL + "/api/users/name/" + userId);
  }

  getUserByName(username: string) {
    return axios.get(
      API_URL + "/api/users/?username=" + encodeURIComponent(username)
    );
  }

  addPixPoint(userId: number, pointsToAdd = 1) {
    return axios.patch(
      API_URL + "/api/users/" + userId + "/pixpoints/add",
      pointsToAdd,
      { headers: authHeader() }
    );
  }

  getPopularCreators(skip = 0, limit = 5) {
    return axios.get(`${API_URL}/api/users/popular/${skip}/${limit}`);
  }
}

export default new UserService();
