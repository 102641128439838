<template>
  <router-link
    :to="path"
    class="navlink mx-2 sm:mx-4 sm:w-16 flex flex-col items-center transition
    hover:text-primary focus:text-primary"
  >
    <i :class="['fas fa-lg', iconClass]"></i> <slot></slot>
  </router-link>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    iconClass: {
      type: String,
      default: ""
    },
    path: {
      type: String,
      default: "/"
    }
  }
});
</script>

<style>
.router-link-exact-active {
  @apply text-primary;
}
</style>
