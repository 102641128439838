export default class Point {
  x: number;
  y: number;

  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  static copy({ x, y }: { x: number; y: number }): Point {
    return new Point(x, y);
  }

  toString(): string {
    return `(${this.x}, ${this.y})`;
  }

  distance(p: Point): number {
    return Math.sqrt(Math.pow(this.x - p.x, 2) + Math.pow(this.y - p.y, 2));
  }
}
