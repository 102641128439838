import AuthService from "@/services/auth.service";
import UserService from "@/services/user.service";
import { User, UserCreate } from "@/assets/User";
import { AuthState, ActionContext } from "./typing";

const initialState = new AuthState(false);

export const auth = {
  namespaced: true,
  state: initialState,
  // font appel au service d'authentification,
  // puis effectuent les mutations adéquates
  actions: {
    async loginFromToken({ commit }: ActionContext, accessToken: string) {
      // initialise la procédure de login
      commit("loggingIn");
      // récupération des infos de l'utilisateur
      try {
        const response = await UserService.getCurrentUser();
        const currentUser: User = { ...response.data };
        commit("loginSuccess", currentUser);
        // stockage des données de l'utilisateur courant dans le store de profil
        commit("profil/userPeekSuccess", currentUser, { root: true });
        return accessToken;
      } catch (error) {
        commit("loginFailure");
        if (error.response) {
          // FastAPI response :
          // error.response.status === 401
          // error.response.data === {detail: "Could not validate credentials"}
          return error.response.data.detail;
        } else if (error.request) {
          // No response
          return error.request;
        }
      }
    },

    login({ commit, dispatch }: ActionContext, userCredentials: UserCreate) {
      // récupération du token d'accès
      return AuthService.login(userCredentials).then(
        accessToken => {
          dispatch("loginFromToken", accessToken);
        },
        error => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    logout({ commit }: ActionContext) {
      AuthService.logout();
      commit("logout");
    },

    register({ commit }: ActionContext, user: UserCreate) {
      return AuthService.register(user).then(
        response => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  // changent l'état stocké dans le store
  mutations: {
    loggingIn(state: AuthState) {
      state.loggingIn = true;
    },
    loginExpired(state: AuthState) {
      state.expired = true;
      state.user = new User();
    },
    loginSuccess(state: AuthState, user: User) {
      state.loggedIn = true;
      state.loggingIn = false;
      state.expired = false;
      state.user = user;
    },
    loginFailure(state: AuthState) {
      state.loggedIn = false;
      state.loggingIn = false;
      state.user = new User();
    },
    logout(state: AuthState) {
      state.loggedIn = false;
      state.user = new User();
    },
    registerSuccess(state: AuthState) {
      state.loggedIn = false;
    },
    registerFailure(state: AuthState) {
      state.loggedIn = false;
    }
  }
};
