






































import Vue from "vue";

import ButtonComponent from "@/components/form/ButtonComponent.vue";

export default Vue.extend({
  name: "Modal",

  components: {
    ButtonComponent
  },

  props: {
    wide: { type: Boolean, default: false }
  },

  computed: {
    wideClass() {
      return {
        "w-wide": this.wide
      };
    }
  },

  methods: {
    toggleSelf() {
      this.$emit("modalToggle");
    }
  }
});
