import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { profil } from "./profil.module";
import { cache } from "./cache.module";
import { editeur } from "./editeur.module";
import { flash } from "./flash.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    editeur,
    cache,
    profil,
    auth,
    flash
  }
});
