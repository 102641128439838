/* eslint-disable @typescript-eslint/camelcase */
export class UserBase {
  username = "";
  email = "";
}

export class UserCreate extends UserBase {
  password = "";

  constructor(username = "", password = "", email = "") {
    super();
    this.username = username;
    this.password = password;
    this.email = email;
  }
}

export class User extends UserBase {
  id = -1;
  join_date: Date = new Date();
  pix_points = 0;
  accessToken: string | null = null;

  constructor(user: User | null = null) {
    super();
    if (user) {
      this.username = user.username;
      this.email = user.email;
      this.id = user.id;
      this.join_date = user.join_date;
      this.pix_points = user.pix_points;
      this.accessToken = user.accessToken;
    }
  }
}
