






























































import Vue from "vue";

import ImageService from "@/services/image.service";
import UserService from "@/services/user.service";
import Image from "@/assets/Image";
import ImageComponent from "@/components/images/Image.vue";

interface DataInterface {
  recentImages: Image[];
  popularImages: Image[];
  creators: string[];
  recentHasMore: boolean;
  popularHasMore: boolean;
  creatorsHasMore: boolean;
}

export default Vue.extend({
  name: "Accueil",

  components: {
    ImageComponent
  },

  data(): DataInterface {
    return {
      recentImages: [],
      popularImages: [],
      creators: [],
      recentHasMore: true,
      popularHasMore: true,
      creatorsHasMore: true
    };
  },

  async mounted() {
    let response = await ImageService.getRecentPublicImages();
    this.recentImages = response.data;
    response = await ImageService.getPopularPublicImages();
    this.popularImages = response.data;
    response = await UserService.getPopularCreators();
    this.creators = (response.data as { username: string }[]).map(
      creator => creator.username
    );
  },

  methods: {
    async loadMoreRecent() {
      const response = await ImageService.getRecentPublicImages(
        this.recentImages.length
      );
      const imagesToAdd = response.data;
      if (imagesToAdd.length > 0)
        this.recentImages = this.recentImages.concat(...imagesToAdd);
      else this.recentHasMore = false;
    },

    async loadMorePopular() {
      const response = await ImageService.getPopularPublicImages(
        this.popularImages.length
      );
      const imagesToAdd = response.data;
      if (imagesToAdd.length > 0)
        this.popularImages = this.popularImages.concat(...imagesToAdd);
      else this.popularHasMore = false;
    },

    async loadMoreCreators() {
      const response = await UserService.getPopularCreators(
        this.creators.length
      );
      const creatorsToAdd = response.data;
      if (creatorsToAdd.length > 0)
        this.creators = this.creators.concat(...creatorsToAdd);
      else this.creatorsHasMore = false;
    }
  }
});
